import ArticleHeading from "@/components/ArticleHeading";
import cloudflareLoader from "@/lib/cloudflare-images";
import colourways from "@/lib/colourways";
import clsx from "clsx";
import Image from "next/legacy/image";

const ArticleModule = ({
  heading,
  headingElement = "h2",
  headingSize = "xl",
  text,
  textPosition = "right",
  images = [],
  pullQuote,
  pullQuoteAttribution,
  textBackgroundColourwayHandle = "white",
}) => {
  const textBackgroundColourway = colourways(textBackgroundColourwayHandle);

  return (
    <div
      className={clsx(
        "ArticleModule",
        "grid grid-cols-2 gap-x-[2px] gap-y-[2px] bg-zinc-950 lg:gap-y-0"
      )}
    >
      <div
        className={clsx(
          "ArticleModule__media",
          "col-span-2 bg-white md:col-span-1",
          {
            "order-1": textPosition === "right",
            "order-1 lg:order-2": textPosition === "left",
          }
        )}
      >
        <div className={clsx("h-full divide-y-2 divide-zinc-950", {})}>
          {!!heading && (
            <div
              className={clsx(
                "relative",
                [images.length === 0 ? "h-full" : ""],
                [`${textBackgroundColourway.bg}`],
                {
                  "text-white": textBackgroundColourway.useLightText,
                  "text-zinc-950": !textBackgroundColourway.useLightText,
                }
              )}
            >
              <div className={clsx("overflow-hidden py-6 lg:py-12")}>
                <ArticleHeading element={headingElement} size={headingSize}>
                  {heading}
                </ArticleHeading>
              </div>
            </div>
          )}

          {images.map((image, index) => {
            return (
              <div key={image.id} className={clsx("relative aspect-4/3")}>
                <Image
                  src={image.url}
                  layout="fill"
                  objectFit="cover"
                  alt={image.title}
                  loader={cloudflareLoader}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={clsx(
          "ArticleModule__text",
          "col-span-2 shrink-0 grow-0 md:col-span-1",
          [`${textBackgroundColourway.bg}`],
          {
            "order-2": textPosition === "right",
            "order-1": textPosition === "left",
            "text-white": textBackgroundColourway.useLightText,
            "text-zinc-950": !textBackgroundColourway.useLightText,
          }
        )}
      >
        <div className={clsx(" p-6 lg:p-12")}>
          {!!pullQuote && (
            <div
              className={clsx("heading-xl--thin")}
              dangerouslySetInnerHTML={{ __html: pullQuote }}
            ></div>
          )}

          {!!text && (
            <div
              className={clsx("body-text 2xl:body-text-lg")}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticleModule;
