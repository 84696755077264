import Ad from "@/src/ads/components/Ad";
import clsx from "clsx";
import Action from "components/Action";
import Body from "components/Body";
import Callout from "components/Callout";
import CompetitionEntry from "components/CompetitionEntry";
import Summary from "components/Summary";
import Image from "components/WrappedImage";
import { useStores } from "hooks/useStores";
import { getAs, getHref, getSidebarImageForEntry } from "lib/helpers";
import { observer } from "mobx-react";
import Link from "next/link";
import { useEffect } from "react";

const EntryContent = observer(({ entry, children, premiumPromotion }) => {
  const callout =
    entry.callout && entry.callout.length > 0 ? entry.callout[0] : null;

  const relatedRecipe =
    entry.relatedRecipe && entry.relatedRecipe.length > 0
      ? entry.relatedRecipe[0]
      : null;

  // Get the main image for this article
  const sidebarImage = getSidebarImageForEntry(entry);

  return (
    (<div className="EntryContent">
      <div
        className={clsx("EntryContent__content", "container max-w-screen-2xl")}
      >
        <div className={clsx("EntryContent__columns", "")}>
          <div className="EntryContent__main">
            {entry.summary && (
              <div className="EntryContent__summary">
                <Summary summary={entry.summary} />
              </div>
            )}

            {entry.body && (
              <div className="EntryContent__body">
                <Body body={entry.body} premiumPromotion={premiumPromotion} />
              </div>
            )}

            {callout && callout.text && (
              <div className="EntryContent__callout">
                <Callout heading={callout.heading} text={callout.text} />
              </div>
            )}

            {entry.isCompetition && <CompetitionEntry entry={entry} />}

            {relatedRecipe && (
              <div className="EntryContent__recipe">
                <Link
                  href={getHref(relatedRecipe)}
                  as={getAs(relatedRecipe)}
                  className="EntryContent__recipeLink">

                  <Action label="View Recipe" />

                </Link>
              </div>
            )}
          </div>

          <Sidebar image={sidebarImage} />
        </div>

        {children && <div className="EntryContent__children">{children}</div>}
      </div>
    </div>)
  );
});

const Sidebar = observer(({ image }) => {
  const { uiStore } = useStores();

  useEffect(() => {
    if (!image) return;

    uiStore.setSidebarImage(image);
    uiStore.setSidebarCaption(image.title);
  }, [image, uiStore]);

  const className = clsx("EntryContent__sidebar", {});

  return (
    <div className={className}>
      {uiStore.sidebarImage && (
        <div key={uiStore.sidebarImage.id} className="EntryContent__image">
          <Image
            src={uiStore.sidebarImage.url}
            width={uiStore.sidebarImage.width}
            height={uiStore.sidebarImage.height}
            alt={uiStore.sidebarImage.title}
            layout="responsive"
          />
          {uiStore.sidebarCaption && (
            <div className="EntryContent__caption">
              {uiStore.sidebarCaption}
            </div>
          )}
        </div>
      )}
      <div className="sticky top-24 pt-12">
        <Ad
          placementName={
            process.env.NEXT_PUBLIC_ADS_PROVIDER === "freestar"
              ? "mob.co.uk_sidewall_left"
              : "htlad-article_left"
          }
        />
      </div>
    </div>
  );
});

EntryContent.defaultProps = {
  entry: null,
};

export default EntryContent;
