import cloudflareLoader from "@/lib/cloudflare-images";
import clsx from "clsx";
import Image from "next/legacy/image";

const HeroImage = ({ image }) => {
  return (
    <div className={clsx("HeroImage")}>
      <div
        className={clsx(
          "relative hidden aspect-square sm:aspect-4/3 lg:block lg:aspect-video 2xl:aspect-2.75/1"
        )}
      >
        <Image
          src={image.url}
          sizes="100vw"
          alt={image.title}
          layout="fill"
          objectFit="cover"
          priority
          loader={cloudflareLoader}
        />
      </div>
    </div>
  );
};

export default HeroImage;
