import Eyebrow from "@/components/Eyebrow";
import cloudflareLoader from "@/lib/cloudflare-images";
import colourways from "@/lib/colourways";
import clsx from "clsx";
import Image from "next/legacy/image";

const MagazineHero = ({
  image,
  imagePosition = "full",
  eyebrow,
  eyebrowHref,
  heading,
  subHeading,
  colourwayHandle = "white",
  headingSize = "xl",
}) => {
  // Get colourway from the colourway handle
  const colourway = colourways(colourwayHandle);

  const _Text = () => {
    return (
      <div className={clsx("space-y-2 px-6 py-12 md:space-y-4 md:px-12")}>
        <Eyebrow href={eyebrowHref} className={clsx("heading-sm--extralight")}>
          {eyebrow}
        </Eyebrow>
        <h1
          className={clsx("", {
            "heading-lg--thin": headingSize === "lg",
            "heading-xl--thin": headingSize === "xl",
            "heading-2xl--thin": headingSize === "2xl",
          })}
        >
          {heading}
        </h1>
        <div className={clsx("body-text-lg !max-w-2xl")}>
          <div
            className={clsx("font-body text-lg [&_p]:last:mb-0")}
            dangerouslySetInnerHTML={{ __html: subHeading }}
          ></div>
        </div>
      </div>
    );
  };

  const _Wrapper = ({ children }) => {
    return (
      <div
        className={clsx(
          "relative  border-b-2 border-black md:aspect-16/9 2xl:aspect-2.75/1",
          `${colourway.bg}`,
          {
            "text-white": colourway.useLightText,
            "text-zinc-800": !colourway.useLightText,
            "aspect-4/3": !image,
            "aspect-square": image && imagePosition === "full",
          }
        )}
      >
        {children}
      </div>
    );
  };

  if (imagePosition === "full") {
    return (
      <_Wrapper>
        <div
          className={clsx(
            "MagazineHero__text",
            "absolute inset-0 z-20 flex flex-col justify-center"
          )}
        >
          <_Text />
        </div>

        {!!image && (
          <div className={clsx("MagazineHero__image", "absolute inset-0 z-10")}>
            <Image
              src={image.url}
              layout="fill"
              objectFit="cover"
              alt={image.title}
              loader={cloudflareLoader}
            />
            <div className={clsx("absolute inset-0 bg-zinc-800/30")}></div>
          </div>
        )}
      </_Wrapper>
    );
  }

  return (
    <_Wrapper>
      <div
        className={clsx("MagazineHero__image", "flex h-full items-center", {
          "flex-col md:flex-row-reverse": imagePosition === "right",
          "flex-col md:flex-row": imagePosition === "left",
        })}
      >
        <div
          className={clsx(
            "relative h-full w-full border-b-2 border-black md:w-1/2 md:border-b-0",
            {
              "md:border-l-2": imagePosition === "right",
              "md:border-r-2": imagePosition === "left",
            }
          )}
        >
          <div
            className={clsx(
              "relative z-10 aspect-square md:absolute md:inset-0 md:aspect-auto"
            )}
          >
            {!!image && (
              <Image
                src={image.url}
                layout="fill"
                objectFit="cover"
                alt={image.title}
                loader={cloudflareLoader}
              />
            )}
          </div>
        </div>
        <div
          className={clsx(
            "MagazineHero__text",
            "relative flex h-full w-full items-center md:w-1/2"
          )}
        >
          <_Text />
        </div>
      </div>
    </_Wrapper>
  );
};

export default MagazineHero;
