import clsx from "clsx";
import Link from "next/link";
import { ArrowLeft } from "react-feather";

const Eyebrow = ({ href, children, className }) => {
  if (!!href) {
    return (
      (<Link
        href={href}
        className={clsx(
          "Eyebrow",
          className,
          "group relative flex items-center gap-2 hover:opacity-75"
        )}>

        <span
          className={clsx(
            "Eyebrow__arrow",
            "translate-x-0 transition duration-300 ease-out group-hover:-translate-x-1 group-hover:opacity-100"
          )}
        >
          <ArrowLeft
            className={clsx(
              "h-5 w-5 stroke-[1.5] lg:h-6 lg:w-6 xl:h-7 xl:w-7"
            )}
          />
        </span>
        <span className={clsx("Eyebrow__content", "inline-block")}>
          {children}
        </span>

      </Link>)
    );
  }

  return <div className={className}>{children}</div>;
};

export default Eyebrow;
